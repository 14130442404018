define("discourse/plugins/twitch-status/discourse/templates/connectors/extra-nav-item/button", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a id="twitch-status-link" href="https://twitch.tv/{{siteSettings.twitch_username}}" target="_blank">
    <img src="/plugins/twitch-status/images/twitch-animated.gif" />
  </a>
  
  */
  {
    "id": "y9+PmJMm",
    "block": "[[[44,[[28,[37,1],null,[[\"siteSettings\"],[[28,[32,0],[\"siteSettings\"],null]]]]],[[[10,3],[14,1,\"twitch-status-link\"],[15,6,[29,[\"https://twitch.tv/\",[52,[30,1,[\"siteSettings\"]],[28,[30,1,[\"siteSettings\"]],null,null],[28,[32,1],[[30,0],\"siteSettings.twitch_username\",\"[\\\"The `siteSettings` property path was used in the `discourse/plugins/twitch-status/discourse/templates/connectors/extra-nav-item/button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/plugins/twitch-status/images/twitch-animated.gif\"],[12],[13],[1,\"\\n\"],[13]],[1]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"a\",\"if\",\"img\"]]",
    "moduleName": "discourse/plugins/twitch-status/discourse/templates/connectors/extra-nav-item/button.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});